import { IVideosServiceMock } from "./videos.types";

export const mockVideosService = (): IVideosServiceMock => ({
  getVideos: jest.fn(),
  getVideo: jest.fn(),
  getEstadosVideos: jest.fn(),
  getTiposNovedad: jest.fn(),
  save: jest.fn(),
  saveAcceptData: jest.fn(),
  saveVisionado: jest.fn(),
  downloadInforme: jest.fn(),
});
