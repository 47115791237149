
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import {
  ICliente,
  IConfColumnaPais,
  IField,
  IPais,
  ISoConfColumnasUpdateDTO,
  IUsuario,
} from "@/entities";
import { EDirection, IColumnaResponse, IPaged } from "@/services";
import { IVideo } from "@/entities/centros-efectivo-backend/video.types";
import VueFilters from "@/vue-filters";
import { Action, Getter } from "vuex-class";
import {
  ESTADO_VIDEO_COMPLETADO,
  ESTADO_VIDEO_VISUALIZADO,
  MILLISECONDS_24_HORAS,
  ROLE_PAIS,
} from "@/constans";
import Pagination from "@/components/common/Pagination.vue";
import SelectColumns from "@/components/common/SelectColumns/SelectColumns.vue";

@Component({
  components: { SelectColumns, Pagination },
  mixins: [VueFilters],
})
export class VideosTable extends Vue {
  @Prop({ required: false, default: 0 }) totalElements!: number;
  @Prop({ required: true }) value!: IPaged;
  @Prop({ required: true }) videos!: IVideo[];
  @Prop({ required: true }) public columnsConfig!: IColumnaResponse;
  @Action("saveColumna") saveColumna!: (
    params: ISoConfColumnasUpdateDTO
  ) => Promise<IColumnaResponse | undefined>;
  @Getter("getLoggedUser") getLoggerUser!: IUsuario;
  @Getter("getClienteSelected") getClienteSelected!: ICliente;
  @Getter("getPaises") getPaises!: IPais[];

  pagination: IPaged = {
    direction: EDirection.DESC,
    orderBy: "fecha",
    page: 1,
    size: 10,
  };

  showPlayVideo(video: IVideo): boolean {
    const diasVisualizacionVideos = video.cliente.pais.diasVisualizarVideos;
    return (
      new Date(video.fechaSubida).getTime() >
        new Date().getTime() - MILLISECONDS_24_HORAS * diasVisualizacionVideos &&
      (video.estado.codParametro === ESTADO_VIDEO_COMPLETADO ||
        video.estado.codParametro === ESTADO_VIDEO_VISUALIZADO)
    );
  }

  get showInforme(): boolean {
    return this.getLoggerUser.nivel === ROLE_PAIS;
  }

  get videosComp(): IVideo[] {
    return this.videos;
  }

  get fields(): IField[] {
    const visibleKeys = this.columnsConfig?.codColumna?.split(",").filter((s) => s !== "") || [];
    let columnas: IField[] = [
      {
        key: "actions-left",
        label: "ACCIONES",
        sortable: false,
        thClass: "table-header table-header-active pl-4",
        tdClass: "table-cell-first",
        visible: true,
        class: "table-cell",
        aux: true,
        code: "",
      },
    ];
    this.columnsConfig.columnas?.forEach((column: IConfColumnaPais) => {
      columnas.push({
        key: column.keyFront,
        label: this.$t("label." + column.codColumna),
        sortable: true,
        thClass: "table-header",
        tdClass: (_value: unknown, key: string): string => `table-cell-middle ${key}`,
        thStyle: column.widthFront ? { width: column.widthFront } : {},
        visible: this.isVisible(visibleKeys, column.codColumna),
        class: "table-cell",
        aux: false,
        code: column.codColumna,
        formatter: (value: any): string => {
          if (column.formatType == "Date") {
            return this.filterDate(value);
          } else if (column.formatType == "Number") {
            return this.filterNumber(value, 2);
          }
          return value;
        },
      });
    });
    columnas.push({
      key: "actions-right",
      label: "",
      sortable: false,
      thClass: "table-header table-header-add-columnas pb-0",
      tdClass: "table-cell",
      visible: true,
      aux: true,
      code: "",
    });
    return columnas;
  }

  set fields(fields: IField[]) {
    this.columnsConfig.codColumna =
      fields
        .filter((item) => item.visible && !item.aux)
        .map((item) => item.code)
        .join(",") || "";
  }

  get visibleFields(): IField[] {
    return this.fields.filter((item) => item.visible);
  }

  isVisible(visibleKeys: string[], column: string): boolean {
    return !visibleKeys.length || !!visibleKeys.find((key) => key === column);
  }
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  clickAcciones(action: string, object?: any): void {
    if (action === "sort") {
      this.pagination.direction = object.sortDesc ? EDirection.DESC : EDirection.ASC;
      this.pagination.orderBy = object.sortBy;
      this.saveColumnsConfig();
      this.$emit("input", this.pagination);
      this.$emit("action", { action: "reject", data: this.pagination });
    } else if (action == "pagination") {
      this.pagination.page = object;
      this.$emit("input", this.pagination);
      this.$emit("action", { action: "reject", data: this.pagination });
    } else if (action == "watch") {
      this.$emit("action", { action: "watch", data: object });
    } else if (action == "download") {
      this.$emit("action", { action: "download", data: object });
    }
  }

  async saveColumnsConfig(): Promise<void> {
    const params: ISoConfColumnasUpdateDTO = {
      codColumna: this.columnsConfig.codColumna || "",
      codTabla: this.columnsConfig.codTabla,
    };
    if (this.pagination) {
      params.codTipoOrden = this.pagination.direction;
      params.numPagElements = this.pagination.size;
      params.codOrden = this.pagination.orderBy;
    }
    await this.saveColumna(params);
  }
}
export default VideosTable;
